import numeral from 'numeral'

import { InternalLink } from 'src/components/atoms/Link'
import { Heading2, ParagraphIntro } from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'

import { Infopanel } from 'src/config/generated/sanitySchema'
import { listPlantingProjectsPath } from 'src/config/paths'
import { env } from 'src/env/client.mjs'
import { ProjectFeaturedOn } from 'src/types/project'
import { trpc } from 'src/utils/trpc'

import { ProjectTile } from '../ProjectTile'

type ProjectsSectionProps = {
  cmsData: Infopanel | undefined
  color?: FullWidthContainerBackgroundColor
  className?: string
  skipContainer?: boolean
}

export const ProjectsSection = ({
  color = FullWidthContainerBackgroundColor.white,
  cmsData,
  className = '',
  skipContainer = false
}: ProjectsSectionProps) => {
  const { data: projectListings } = trpc.useQuery(
    [
      'projects.listProjects',
      {
        purposes: [],
        regionId: undefined,
        limit: 2,
        offset: 0,
        orderBy: 'CreatedOn',
        featuredOn: [ProjectFeaturedOn.Home, ProjectFeaturedOn.All]
      }
    ],
    {
      refetchOnWindowFocus: false
    }
  )

  if (!cmsData) return null
  if (projectListings?.Projects.length === 0) return null
  const DynamicContainer = skipContainer ? `div` : FullWidthContainer

  return (
    <DynamicContainer
      className={`!pb-0 ${className}`}
      backgroundColor={color}
      id='section-planter'
    >
      <div className='flex flex-col gap-4 md:gap-[50px]'>
        <div>
          <Heading2>{cmsData.title}</Heading2>
          <ParagraphIntro className='font-medium !m-0'>
            {cmsData.subtitle}{' '}
            <InternalLink
              color='black-underlined'
              href={listPlantingProjectsPath}
            >
              View all 2024 projects
            </InternalLink>
          </ParagraphIntro>
        </div>
        <div className='flex flex-col md:flex-row gap-5 md:gap-10'>
          {projectListings?.Projects.map(project => (
            <ProjectTile
              className='flex-1'
              key={project.Id}
              ProjectName={project.Name}
              ImageUrl={`${env.NEXT_PUBLIC_ASSETS_DOMAIN}/${project.Hero}`}
              Purpose={project.Purpose}
              Uri={project.Uri!}
              RegionName={project.Region?.Name}
              DescriptionValue={`${numeral(
                project.Applications_aggregate.aggregate?.sum?.Approved
              ).format('0,0')}`}
              DescriptionText='trees received.'
            />
          ))}
          {projectListings?.Projects.length === 1 ? (
            <div className='flex-1' />
          ) : null}
        </div>
      </div>
    </DynamicContainer>
  )
}
