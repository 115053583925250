import { IoLocation } from 'react-icons/io5'

import { Image } from 'src/components/atoms/Image'
import { InternalLink } from 'src/components/atoms/Link'
import { Heading4, ParagraphNoSpace } from 'src/components/atoms/Typography'

import { viewProjectPath } from 'src/config/paths'
import { camelToFlat } from 'src/utils/common'

type ProjectTileProps = {
  ImageUrl: string
  RegionName?: string
  ProjectName: string
  DescriptionValue?: string
  DescriptionText?: string
  Purpose: string[]
  Uri: string
  className?: string
}

export const ProjectTile = ({
  ImageUrl,
  RegionName,
  ProjectName,
  DescriptionValue,
  DescriptionText,
  Purpose,
  Uri,
  className = ''
}: ProjectTileProps) => {
  return (
    <InternalLink
      className={`flex flex-col bg-mostlyGreen hover:shadow-[0px_0px_20px_0px_rgba(0,_0,_0,_0.25)] transition-shadow duration-300 ${className}`}
      href={viewProjectPath({
        routeParams: {
          uri: Uri
        }
      })}
    >
      <div className='relative w-full h-80 sm:h-[380px] md:h-60 lg:h-[410px]'>
        <Image
          alt={ProjectName}
          src={ImageUrl}
          fill
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div className='flex-1 flex flex-col gap-[10px] p-5 md:p-[30px] text-white'>
        <div className='flex flex-col flex-1 gap-2'>
          <Heading4 className='lg:!text-[20px] !font-bold !m-0'>
            {ProjectName}
          </Heading4>
          {RegionName ? (
            <div>
              <IoLocation className='w-6 h-6 -translate-y-1 -translate-x-1 inline' />
              <ParagraphNoSpace className='text-[20px] font-medium inline'>
                {RegionName}
              </ParagraphNoSpace>
            </div>
          ) : null}
          <ParagraphNoSpace>
            <span className='font-bold text-[18px] md:text-[20px]'>
              {DescriptionValue}
            </span>{' '}
            {DescriptionText}
          </ParagraphNoSpace>
        </div>
        {Purpose.length ? (
          <div className='flex flex-row flex-wrap gap-[5px]'>
            {Purpose.map(p => (
              <div
                key={`purpose-${p}`}
                className='bg-[#f5f5f5] text-mostlyGreen py-1 px-2 text-[13px] font-semibold'
              >
                {camelToFlat(p)}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </InternalLink>
  )
}
